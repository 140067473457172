import type { AxiosError, AxiosInstance, InternalAxiosRequestConfig, AxiosResponse } from 'axios'
import axios from 'axios'
import { useGenerateStore } from "@/store/modules/generate";
// import { useEnv } from '@/hooks'

// const { AIPPT_ENV, AIPPT_HOST, AIPPT_TIMEOUT } = useEnv()

// env内容
// const AIPPT_ENV = 'local';
const AIPPT_HOST = import.meta.env.SERVER_API;
const AIPPT_TIMEOUT = 30 * 1000;
const service: AxiosInstance = axios.create({
  baseURL: AIPPT_HOST,
  timeout: AIPPT_TIMEOUT,
  headers: { 'Content-Type': 'application/json;charset=UTF-8' }
})

service.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const generateStore = useGenerateStore();
  let token = localStorage.getItem('token');
  if(token){
    token = JSON.parse(token);
  }
  const accessToken = token?.access_token;
  if(generateStore.generate.tenant_key){
    config!.headers!['Q-Token'] = generateStore.generate.tenant_obj?.token;
    config!.headers!['Q-Uuid'] = generateStore.generate.tenant_obj?.uuid;
    config!.headers!['Q-Apikey'] = generateStore.generate.tenant_obj?.apikey;
  }else{
    config!.headers!.Authorization = `Bearer ${accessToken}`
  }
  return config 
})

service.interceptors.response.use(
  (response: AxiosResponse) => {
    const data = response.data
    if ((data.status === 0 && data.status_code === 14006) || data.code === 14006) {
      // if (AIPPT_ENV === 'local') {
      //   const userStore = useUserStore()
      //   userStore.$state.state.isLogin = false
      // } else {
      //   window.location.href = AIPPT_HOST
      // }
      const userStore = useUserStore()

      userStore.$state.state.isLogin = false
    }
    if (response.status === 200) {
      return data
    } else {
      return Promise.reject(data)
    }
  },
  (err) => {
    return Promise.reject(err.response)
  }
)

export const request = {
  get<T = any>(url: string, data?: any): Promise<T> {
    return request.request('GET', url, { params: data })
  },
  post<T = any>(url: string, data?: any): Promise<T> {
    return request.request('POST', url, {
      data,
      headers: {}
    })
  },
  put<T = any>(url: string, data?: any): Promise<T> {
    return request.request('PUT', url, { data })
  },
  delete<T = any>(url: string, data?: any): Promise<T> {
    return request.request('DELETE', url, { params: data })
  },
  request<T = any>(method = 'GET', url: string, data?: any): Promise<T> {
    return new Promise((resolve, reject) => {
      service({ method, url, ...data })
        .then((res) => {
          resolve(res as unknown as Promise<T>)
        })
        .catch((e: Error | AxiosError) => {
          reject(e)
        })
    })
  }
}
