export function extractMdContent(str: string) {
  const newStr = str.replace(/^`+|`+$/g, '');
  const result = newStr.match(/\r?\n?#/);
  if (result != null) {
    return newStr.slice(result.index);
  } else {
    return str;
  }
}

export function getUrlParam(name:string){
  let paramsStr = window.location.search;
  if(!paramsStr){
    paramsStr = '?'+window.location.href.split('?')[1]
  }
  const params = new URLSearchParams(paramsStr)
  return params.get(name)
}
