import { defineStore } from "pinia";

import { store } from "@/store";

import { useStorage } from "@vueuse/core";
import SafeStringify from "json-stringify-safe";
import * as LZString  from 'lz-string';


export const useGenerateStore = defineStore("generate", () => {
  let tenant_obj = {};
  if(sessionStorage.tenant_obj){
    try {
      tenant_obj = JSON.parse(sessionStorage.tenant_obj)
    } catch (error) {}
  }
  const generate = {
    json: sessionStorage.templates ?JSON.parse( LZString.decompress(sessionStorage.templates)): {},
    userInfo: tenant_obj.uuid?tenant_obj:null,
    isLogin: false,
    titleComponent: null,
    bgContent:null,
    id: null,
    tenant_key:sessionStorage.tenant_key||'',
    tenant_obj:tenant_obj
  };


  function changeData(json:any){
    generate.json = json;
    sessionStorage.setItem("templates", json?LZString.compress(SafeStringify(json)):'');
  }

  function setTenant_key(tenant_key:any){
    generate.tenant_key = tenant_key;
  }
  function setTenant_obj(obj:any){
    generate.tenant_obj = obj;
  }


  function changeUserInfo(userInfo: any){
    generate.isLogin = true;
    generate.userInfo = userInfo;
  }
  function changeTitleComponent(titleComponent: any,id,bgContent){
    generate.titleComponent = titleComponent;
    generate.id = id;
    generate.bgContent = bgContent;
  }

  return {
    generate,
    changeData,
    changeUserInfo,
    changeTitleComponent,
    setTenant_key,
    setTenant_obj
  };
});

// 非setup
export function useGenerateStoreHook() {
  return useGenerateStore(store);
}
