import js_cookie_1 from "js-cookie";
import { v4 } from "uuid";

var isWXA = typeof wx !== "undefined" && !!wx.setStorageSync;
var isBrowser = typeof window !== "undefined" && !isWXA;
const COOKIE_NAME = "user_device_id";
const TIMESTAMP = "user_device_id_timestamp";

export function mathRNG() {
  var rnds = new Array(16);

  for (var i = 0, r = 0; i < 16; i++) {
    if ((i & 0x03) === 0) r = Math.random() * 0x100000000;
    rnds[i] = (r >>> ((i & 0x03) << 3)) & 0xff;
  }

  return rnds;
}

export function getUUID() {
  var getRandomValues =
    typeof crypto != "undefined" &&
    crypto.getRandomValues &&
    crypto.getRandomValues.bind(crypto);
  var options = getRandomValues ? undefined : { rng: mathRNG };

  return v4(options).replace(/-/g, "");
}
/**
 * 获取创建 deviceId 的时间
 *
 * @export
 * @returns
 */
export function getTimestamp() {
  if (!isBrowser && !isWXA) return null;

  var time;

  if (isBrowser) {
    time = js_cookie_1.get(TIMESTAMP);
  } else if (isWXA) {
    try {
      time = wx.getStorageSync(TIMESTAMP);
    } catch (e) {
      // ignore error
    }
  }

  if (time) {
    return +time;
  }

  return null;
}
export function setUserDeviceId(value, fromOldId) {
  if (fromOldId === void 0) {
    fromOldId = false;
  }

  if (isBrowser) {
    var names = window.location.hostname.split(".");
    var hostnames = names.slice(-1);

    names = names.slice(0, -1);

    var isSuccess = false;
    var domain = window.location.hostname;

    while (names.length) {
      hostnames.unshift(names.pop());

      var d = (names.length ? "." : "") + hostnames.join(".");

      js_cookie_1.set(COOKIE_NAME, value, {
        expires: 365 * 3,
        domain: d,
        sameSite: "None",
        // 处理在 iframe 下因为安全策略导致无法正常读取cookie
        secure: true,
      });

      if (js_cookie_1.get(COOKIE_NAME)) {
        isSuccess = true;
        domain = d;
        break;
      }
    }

    if (!isSuccess) {
      js_cookie_1.set(COOKIE_NAME, value, {
        domain: domain,
        sameSite: "None",
        // 处理在 iframe 下因为安全策略导致无法正常读取cookie
        secure: true,
      });
    }

    if (!fromOldId) {
      js_cookie_1.set(TIMESTAMP, Date.now() + "", {
        domain: domain,
        sameSite: "None",
        // 处理在 iframe 下因为安全策略导致无法正常读取cookie
        secure: true,
        expires: 365 * 3,
      });
    }

    return value;
  }

  if (isWXA) {
    try {
      var now = Date.now() + "";
      // patch: 同步方法设置之后，虽然 wx.getStorageSync 可以取到值，但是 Storage 没有，用异步再次设置一遍
      wx.setStorageSync(COOKIE_NAME, value);
      wx.setStorageSync(TIMESTAMP, now);
      wx.setStorage({
        key: COOKIE_NAME,
        data: value,
      });
      wx.setStorage({
        key: TIMESTAMP,
        data: now,
      });

      return value;
    } catch (e) {
      // ignore error
    }
  }
  return value;
}
/**
 *判断是否有用户凭证
 *
 * @export
 * @returns
 */
export function hasUserDeviceId() {
  if (isBrowser) {
    var token = js_cookie_1.get(COOKIE_NAME);

    if (token) return true;
  }

  if (isWXA) {
    try {
      var token = wx.getStorageSync(COOKIE_NAME);

      if (token) return true;
    } catch (error) {
      // ignore error
    }
  }

  return false;
}
/**
 * 获取用户唯一凭证， 如果没有会生成一个
 *
 * @export
 * @returns
 */
export function getUserDeviceId() {
  if (isBrowser) {
    var token = js_cookie_1.get(COOKIE_NAME);
    
    if (token) {
      return token;
    }
  }
  
  if (isWXA) {
    try {
      var token = wx.getStorageSync(COOKIE_NAME);
      
      if (token) return token;
    } catch (error) {
      // ignore error
    }
  }
  
  return setUserDeviceId(getUUID());
}
