
<script setup lang="ts">
import { reactive, onMounted } from 'vue';
import { login, wechatCheck, getUserInfo } from '@/apis';
import { useRoute } from 'vue-router';
const emit = defineEmits(["success", "close"]);


const states = reactive({
    img:'',
    sign: '',
    token: null,
    timer: null,
})


/**
 * 获取url中的from参数
 */
const route = useRoute();
const { from,bd_vid, qhclickid } = route.query;

async function load(){
    const { data } = await login();
    states.img = data.url;
    states.sign = data.sign;
    states.timer = setInterval(async () =>{
        const { data: token } = await wechatCheck(states.sign, localStorage.getItem('from')||from,localStorage.getItem('bd_vid')||bd_vid,localStorage.getItem('qhclickid')||qhclickid)
        if(token.access_token){
            states.token = token;
            localStorage.setItem('token', JSON.stringify(token));
            clearInterval(states.timer);
            emit("success");
            if(token.is_new_user){
                window.uetq = window.uetq || [];
                window.uetq.push('event', 'register', {});
            }
        }
    }, 1000)
}

function close(){
    clearInterval(states.timer);
    emit("close");
}
function openArgeement(){
    window.open('/agreement');
}
onMounted(() =>{
    load();
})
</script>

<template>
    <div class="login-wrap">
        <div class="login">
            <img src="./../assets/close.svg" @click="close" class="close" alt="">
            <img src="./../assets/logo-left.svg" class="logo-left" alt="">
            <div class="logo-text">轻竹办公</div>
            <div class="wechat">
                <img src="./../assets/wechat.svg" alt="">
                微信扫码 一键登录
            </div>
            <div class="code">
                <img :src="states.img" alt="">
            </div>
            <div class="desc">
                登录即表示同意
                <span class="agreement" @click="openArgeement">《用户协议》、</span>
                <span class="agreement" @click="openArgeement">《隐私协议》</span>
            </div>
        </div>
    </div>
</template>

<style scoped>
.login-wrap{
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.30);
    z-index: 2059;
    font-family: PingFang SC;
}
.login{
    width: 384px;
    height: 480px;
    flex-shrink: 0;
    border-radius: 24px;
    background: #FFF;
    box-shadow: 0px 17px 32px 0px rgba(0, 0, 0, 0.05);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}
.close{
    position: absolute;
    right: 16px;
    top: 16px;
    height: 30px;
    cursor: pointer;
}
.logo-left{
    height: 55px;
}
.logo-text{
    color: #232922;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 7px 0 8px 0;
}
.wechat{
    display: flex;
    align-items: center;
    color: #232922;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 112.5% */
    > img{
        height: 15px;
        margin-right: 4px;
    }
}
.code{
    width: 224px;
    height: 224px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #DDDEDC; 
    margin: 24px 0 24px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    > img{
        max-width: 98%;
        max-height: 98%;
    }
}
.desc{
    font-size: 14px;
}
.desc .agreement{
    color: var(--blue-blue-500, #298EFF);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    cursor: pointer;
}
</style>